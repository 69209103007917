/**
 * Opens a file from the local file system.
 * @param {string} accept the accept-attribute
 * @returns {Promise<File>}
 */
const fileInput = (accept = '*/*', multiple = true) => {
  const hiddenInput = document.createElement('input');
  hiddenInput.type = 'file';
  hiddenInput.accept = accept;
  hiddenInput.multiple = multiple;

  document.body.appendChild(hiddenInput);

  return new Promise((resolve) => {
    hiddenInput.addEventListener('change', (event) => {
      const files = event.target.files;
      hiddenInput.parentNode.removeChild(hiddenInput);
      if (multiple) {
        resolve(files);
      } else {
        resolve(files[0]);
      }
    });

    hiddenInput.click();
  });
};

export default fileInput;
